import makeSwal from './helpers';

$(() => {
  $.ajaxSetup({
    headers: { 'X-CSRF-TOKEN': window.Atabase.csrfToken },
  });
});

_('#sync-mycharlie-sites').on('click', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('data-url'))
    .then((response) => {
      makeSwal(response.data);
    });
});

_('#mycharlie-auto-create-site').on('click', (event) => {
  swal({
    title: 'Automatically create site',
    text: 'Do you really want to automatically create a site for this mycharlie site?',
    type: 'question',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
  }).then((result) => {
    if (result) {
      axios.post(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});

_('#branch-store').on('submit', (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  axios.post(_(e.target).attr('action'), data)
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#branch-update').on('submit', (e) => {
  e.preventDefault();
  // const data = new FormData(e.target);
  const data = $('#branch-update').serialize();
  axios.put(_(e.target).attr('action'), data)
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#branches-status-store').on('submit', (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  axios.post(_(e.target).attr('action'), data)
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#branches-status-update').on('submit', (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  axios.post(_(e.target).attr('action'), data)
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#branches-facility-store').on('submit', (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  axios.post(_(e.target).attr('action'), data)
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#branches-facility-update').on('submit', (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  axios.post(_(e.target).attr('action'), data)
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// add facilities feature
_('#branch-facilities-add').on('click', (e) => {
  const branch_id = e.target.getAttribute('data-branch-id');
  axios.get(`/branches/${branch_id}/facilities/unassigned`)
  .then((response) => {
    const facilityItems = $('.facility');
    swal({
      title: 'Select a Facility',
      input: 'select',
      inputOptions: response.data,
      inputPlaceholder: 'required',
      showCancelButton: true,
      inputValidator: value => new Promise((resolve, reject) => {
        if (value !== '') {
          resolve();
        } else {
          reject('You need to select a Facility');
        }
      }),
    }).then((result) => {
      $('#facility-table tr:last').before(`<tr><td style="width: 178px;"><label for="${result}">${response.data[result]}</label></td><td><input name="facilities[${result}][value]" type="number"></td></tr>`);
    });
  })
  .catch((axiosError) => {
    makeSwalValidation(axiosError);
  });
});

// add branch period feature
_('#branch-pricing-period-store').on('submit', (e) => {
  axios.post(_(e.target).attr('data-url'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// facility delete buttons
_('.facility-edit-delete').on('click', (e) => {
  e.preventDefault();
  swal({
    title: 'Delete Facility',
    text: 'Do you really want to delete the facility?',
    type: 'warning',
    confirmButtonText: 'Delete',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(e.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});

_('#branch-opening-hours-store').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#branch-pricing-period-store').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#branches-pricing-period-update').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

function handleTabActivation() {
  if ($('#panel-pricing:visible').length) {
    let date = false;
    if (_('#panel-pricing-current-period').length) {
      date = new Date(_('#panel-pricing-current-period').attr('data-latest-start-date'));
      date.setMonth(date.getMonth() + 1);
    }
    flatpickr('input[name=start_at]', {
      minDate: date,
      dateFormat: 'Y-m-d',
    });

    if (!$.fn.dataTable.isDataTable('#panel-branch-pricing-periods-table')) {
      $('#panel-branch-pricing-periods-table').dataTable({
        ajax: {
          url: $('#panel-branch-pricing-periods-table').data('url-table'),
          data: (data) => { },
        },
        order: [[1, 'asc']],
        columnDefs: [
          { type: 'date-euro', targets: 0 },
        ],
        columns: [
          { data: 'start_at' },
          { data: 'end_at' },
          { data: 'currency_iso' },
          { data: 'vat_percentage' },
          { data: 'toilet_price' },
          { data: 'toilet_price_token' },
          { data: 'shower_price' },
          { data: 'toilet_price_lc' },
          { data: 'discount_ticket' },
          { data: 'toilet_revenue_percentage' },
          { data: 'toilet_arp_percentage' },
          { data: 'shower_revenue_percentage' },
          { data: 'shower_arp_percentage' },
          { data: 'invoice_raise_percentage' },
          { data: null, name: '', width: '120px', sortable: false, searchable: false, render: function(data, type, row) {
              if (row.links.hasOwnProperty('edit')) {
                return `<a class="table-show-link" href="${row.links.edit}"><i class="fa fa-edit"></i> Edit</a>`;
              }

              return '';
            }},
        ],
      });
    }
  }

  if ($('#panel-hours-periods:visible').length) {
    const now = new Date();
    now.setMonth(now.getMonth() + 1);
    flatpickr('input[name=start_at]', {
      dateFormat: 'Y-m-d',
    });


    flatpickr('input[type=time]', {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true,
      onChange: (selectedDates, datestr, instance) => {
        const day = instance.element.id.split('_')[0];
        $(`#${day}_icon_closed`).hide();
        $(`#${day}_icon_open`).show();
      }
    });

    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach((day) => {
      $(`#${day}_icon_open`).click(() => {
        $(`#${day}_open`)[0]._flatpickr.clear();
        $(`#${day}_close`)[0]._flatpickr.clear();

        $(`#${day}_icon_open`).hide();
        $(`#${day}_icon_closed`).show();
      });
    });


    function formatRow(row, day) {
      const open = row[`${day}_open`];
      const close = row[`${day}_close`];

      if (open === '0:00' && close === '0:00') {
        return 'closed';
      }

      return `${open} - ${close}`;
    }

    if (!$.fn.dataTable.isDataTable('#panel-hours-period-table')) {
      $('#panel-hours-period-table').dataTable({
        ajax: {
          url: $('#panel-hours-period-table').data('url'),
          data: (data) => { },
        },
        order: [[1, 'asc']],
        columnDefs: [
          { type: 'date-euro', targets: 0 },
        ],
        columns: [
          { data: 'start_at' },
          { data: 'end_at' },
          { data: null,
            render: (data, type, row) => formatRow(row, 'monday')},
          { data: null,
            render: (data, type, row) => formatRow(row, 'tuesday')},
          { data: null,
            render: (data, type, row) => formatRow(row, 'wednesday') },
          { data: null,
            render: (data, type, row) => formatRow(row, 'thursday') },
          { data: null,
            render: (data, type, row) => formatRow(row, 'friday') },
          { data: null,
            render: (data, type, row) => formatRow(row, 'saturday') },
          { data: null,
            render: (data, type, row) => formatRow(row, 'sunday') },
        ],
      });
    }
  }

  if ($('#panel-turnstiles:visible').length) {
    if (!$.fn.dataTable.isDataTable('#panel-turnstile-table')) {
      $('#panel-turnstile-table').dataTable({
        ajax: {
          url: $('#panel-turnstile-table').data('url-table'),
          data: (data) => { },
        },
        columns: [
          { data: 'code' },
          { data: 'transactions_last' },
          { data: 'transactions_current' },
          { data: 'type' },
          { data: 'current_period.last_seen' },
          { data: null,
            sortable: false,
            searchable: false,
            render: (data, type, row) => `<a class="table-show-link" href="${$('#panel-turnstile-table').data('url-turnstile-show').replace('_id_', row.id)}" target="_blank"><i class="fa fa-search"></i> Details</a>`,
          },
        ],
      });
    }
  }
}

$('.tabs').on('change.zf.tabs', (event) => {
  handleTabActivation();
});

_(document).ready(() => {
  handleTabActivation();


  if (_('.choices').length) {
    const selectBox = new Choices('.choices');

    if (('#branch_id').length) {
      // console.log(selectBox)
      // const branchId = _('#branch_id').attr('data-branch');
      // selectBox.setChoiceByValue(branchId);
      // _('#branch_id').trigger('change');

      // console.log('branchId', branchId);

      // TODO: set id
    }
  }

  flatpickr('input[name=last_closed_at]', {});
  flatpickr('input[name=temporary_closed_starts_at]', {});
  flatpickr('input[name=temporary_closed_ends_at]', {});
  flatpickr('input[name=operational_date]', {});
  flatpickr('input[name=contract_start]', {});
  flatpickr('input[name=contract_end]', {});

  if (!$.fn.dataTable.isDataTable('#branch-status-index')) {
    $('#branch-status-index').dataTable({
      order: [[4, 'asc']],
      ajax: {
        url: $('#branch-status-index').data('url'),
        data(data) { },
      },
      columns: [
        { data: 'id', width: '80px' },
        { data: 'name_short' },
        { data: 'name' },
        { data: 'created_at', width: '190px' },
        { data: null,
          name: '',
          width: '120px',
          sortable: false,
          searchable: false,
          render(data, type, row) {
            return `<a class="table-show-link" href="${$('#branch-status-index').data('url-show').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>`;
          } },
      ],
    });
  }

  if (!$.fn.dataTable.isDataTable('#branch-facility-index')) {
    $('#branch-facility-index').dataTable({
      order: [[3, 'asc']],
      ajax: {
        url: $('#branch-facility-index').data('url'),
        data(data) { },
      },
      columns: [
        { data: 'id', width: '80px' },
        { data: 'name' },
        { data: 'created_at', width: '190px' },
        { data: null,
          name: '',
          width: '120px',
          sortable: false,
          searchable: false,
          render(data, type, row) {
            return `<a class="table-show-link" href="${$('#branch-facility-index').data('url-show').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>`;
          } },
      ],
    });
  }
});

_('#branch-show-delete').on('click', (event) => {
  swal({
    title: 'Branch inactive',
    text: 'Do you really want to set the branch to inactive?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});

_('#branch-status-delete').on('click', (event) => {
  swal({
    title: 'Status inactive',
    text: 'Do you really want to set the status to inactive?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});

_('#branch-facility-delete').on('click', (event) => {
  swal({
    title: 'Facility inactive',
    text: 'Do you really want to set the facility to inactive?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});


// attach a new user to the branch
_('#branch-users-add').on('click', (e) => {
  const get_unassigned_url = e.target.getAttribute('data-unassigned-users-url');
  const branch_id = e.target.getAttribute('data-branch-id');
  axios.get(get_unassigned_url)
    .then((response) => {
      swal({
        title: 'Select a user to assign',
        input: 'select',
        inputOptions: response.data,
        class: 'choices',
        inputPlaceholder: 'Select a user',
        showCancelButton: true,
        inputValidator: value => new Promise((resolve, reject) => {
          if (value !== '') {
            resolve();
          } else {
            reject('You need to select a Facility');
          }
        }),
      }).then((result) => {
        if (result) {
          const assign_user_url = `/branches/${branch_id}/users/attach/${result}`;
          axios.post(assign_user_url)
            .then((response) => {
              makeSwal(response.data);
            });
        }
      });
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// dettach a user from the branch
_('.branch-user-detach').on('click', (event) => {
  swal({
    title: 'Detach the user',
    text: 'Do you really want to detach the user from this branch?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).closest('[data-url]').attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});

_('.branch-user-add').on('click', (event) => {
  event.preventDefault();
  if (!_('#select-user').val()) {
    return;
  }
  axios.get(_(event.target).attr('data-url') + _('#select-user').val())
    .then((response) => {
      const inputs = _('#users-table tbody [name="branch_users[]"]');
      if (inputs.length) {
        for (const i in inputs) {
          if (inputs[i].value == response.data.user.id) {
            makeSwal({
              title: 'User already added',
              type: 'warning',
              text: 'The user is already added to the selection.'
            });

            return false;
          }
        }
      }

      const html = `<tr>
        <td>${response.data.user.first_name}</td>
        <td>${response.data.user.last_name}</td>
        <td>${response.data.user.email}</td>
        <td>
          <input type="hidden" name="branch_users[]" value="${response.data.user.id}" />
          <button class="button radius settings small alert branch-user-remove"><i class="fa fa-trash"></i></button>
        </td>
      </tr>`;
      _('#users-table tbody').append(html);

      makeSwal(response.data.swal);
    });
});

$('#users-table').delegate('.branch-user-remove', 'click', (e) => {
  e.preventDefault();

  const row = $(e.target).closest('tr')
  const name = $(row).find('td')[0].innerText + ' ' + $(row).find('td')[1].innerText;

  $(row).remove();

  makeSwal({
    title: 'User removed',
    type: 'error',
    text: name + ' has been removed from the selection'
  });
});

/**
 * Clone a filter field to be able to filter on more values for that key
 */
_('[data-clone]').on('click', (e) => {
  e.preventDefault();

  const wrap = _(e.target).parent().find('.clone-wrap');
  const target = _(`.${_(e.target).attr('data-clone')}`)[0];
  const cloneWrap = document.createElement('div');
  cloneWrap.className = 'clone';
  const closeIcon = document.createElement('i');
  closeIcon.className = 'fa fa-close remove-filter-clone-button';
  const clone = target.cloneNode(true);

  cloneWrap.appendChild(clone);
  cloneWrap.appendChild(closeIcon);
  _(wrap).append(cloneWrap);
});

_(window).on('click', '.remove-filter-clone-button', (e) => {
  e.preventDefault();

  _(e.target).parent('.clone').remove();
});

/**
 * (Re)Load the table data
 */
function loadTable() {
  const table = $('#branches-index-table');
  let url = table.attr('data-url');
  const dataTable = $('#branches-index-table').dataTable().api();

  if (document.getElementById('filterForm')) {
    const form = document.getElementById('filterForm');
    const formData = new FormData(form);

    const newUrl= new URLSearchParams(formData).toString();

    url += `?${newUrl}`;
  }

  dataTable.ajax.url(url).load();
}

/**
 * Submit filter form
 */
if (document.getElementById('filterForm')) {
  _('form').on('submit', (e) => {
    e.preventDefault();
    loadTable();
  });
}
